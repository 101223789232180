<template>
  <div>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="MonitorIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Sistema</span>
        </template>
        <workflowConfiguracoesSistema :optionsLocal="optionsLocal" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserCheckIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Grupo de Acesso</span>
        </template>
        <workflowConfiguracoesWorkflow :optionsLocal="optionsLocal" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Usuário</span>
        </template>
        <workflowConfiguracoesUsuario :optionsLocal="optionsLocal" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Modelos Documentos</span>
        </template>
        <workflowConfiguracoesModeloDocumentos :optionsLocal="optionsLocal" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Gerenciamento de LOG</span>
        </template>
        <workflowConfiguracoesLOG :optionsLocal="optionsLocal" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit3Icon" size="18" class="mr-50" />
          <span class="font-weight-bold">Assinatura Digital</span>
        </template>
        <workflowConfiguracoesAssinaturaDigital :optionsLocal="optionsLocal" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import workflowConfiguracoesSistema from './workflowConfiguracoesSistema.vue'
  import workflowConfiguracoesWorkflow from './workflowConfiguracoesWorkflow.vue'
  import workflowConfiguracoesUsuario from './workflowConfiguracoesUsuario.vue'
  import workflowConfiguracoesModeloDocumentos from './workflowConfiguracoesModeloDocumentos.vue'
  import workflowConfiguracoesLOG from './workflowConfiguracoesLOG.vue'
  import workflowConfiguracoesAssinaturaDigital from './workflowConfiguracoesAssinaturaDigital.vue'
  export default {
    components: {
      workflowConfiguracoesSistema,
      workflowConfiguracoesWorkflow,
      workflowConfiguracoesUsuario,
      workflowConfiguracoesModeloDocumentos,
      workflowConfiguracoesLOG,
      workflowConfiguracoesAssinaturaDigital,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: this.generalData,
      }
    },
  }
</script>
